import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import { StaticQuery, graphql } from "gatsby"
import Header from './Header'
import Nav from './Nav'

import 'react-image-lightbox/style.css'
import './css/outdatedbrowser.min.css'
import './scss/main.scss'

class Layout extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: 'is-loading',
      stickyNav: false
    }
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
      this.setState({loading: ''});
    }, 1000);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({stickyNav: false}));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({stickyNav: true}));
  }

  _handlePositionChange = (position) => {
    // handle the case where the browser automatically jumps to the last scroll position (e.g. after a refresh)
    if (position.currentPosition === 'above' && !this.state.stickyNav) {
      this._handleWaypointLeave()
    }
  }

  render () {
    const {
      children,
      navVisible,
    } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div className={`body ${this.state.loading}`}>
            <Helmet
              title={data.site.siteMetadata.title}
              // meta={[
              //   { name: 'description', content: 'Sample' },
              //   { name: 'keywords', content: 'sample, something' },
              // ]}
            />

            <Header />

            {
              navVisible &&
              <Waypoint
                onPositionChange={this._handlePositionChange}
                onEnter={this._handleWaypointEnter}
                onLeave={this._handleWaypointLeave}
              >
              </Waypoint>
            }
            {
              navVisible &&
              <Nav sticky={this.state.stickyNav} />
            }

            <div id="wrapper">
              {children}
            </div>
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navVisible: PropTypes.bool.isRequired,
}

Layout.defaultProps = {
  navVisible: true
}

export default Layout
