import React from 'react'

const year = (new Date()).getFullYear()

const CopyrightNote = () => (
  <div className="copyright">
    &copy; Perspectual Limited {year}. All rights reserved.
  </div>
)

export default CopyrightNote
