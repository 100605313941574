import React from 'react'
import logo from './images/logo.svg';

const Header = () => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="Perspectual Logo" /></span>
    </header>
)

export default Header
