import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const SCROLL_OFFSET = -10

const Nav = (props) => (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <Scrollspy items={ ['services', 'technologies', 'showcases', 'contact'] } currentClassName="is-active" offset={-50}>
            <li>
                <Scroll type="id" element="services">
                    <a href="#services">Services</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="technologies" offset={SCROLL_OFFSET}>
                    <a href="#technologies">Technologies</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="showcases" offset={SCROLL_OFFSET}>
                    <a href="#showcases">Case Studies</a>
                </Scroll>
            </li>
            <li className="contact">
                <Scroll type="id" element="contact" offset={SCROLL_OFFSET}>
                    <a href="#contact">Contact</a>
                </Scroll>
            </li>
        </Scrollspy>
    </nav>
)

export default Nav
